import { navigate } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";

export default function Invitation(props: any) {
  const code: string = props["*"];

  React.useEffect(() => {
    if (/^[a-z0-9]+$/i.test(code)) {
      window.location.replace(
        `https://plan3t.page.link/?link=https://plan3t.one/_app/invite/?ref=${code}&apn=one.plan3t.app&isi=1525284698&ibi=one.plan3t.app`
      );
    } else {
      const _ = navigate("/");
    }
  }, [code]);

  return null;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
